@import '../ui_imports';

button,
input,
select,
textarea {
  // This matches Bootstrap 3. In the future we may only want to do this for placeholders
  font-weight: $font-weight-base;
}

label {
  input[type='checkbox']:not(.checkbox),
  input[type='radio'] {
    margin-right: 0.5rem;
    vertical-align: middle;
  }
}

.checkbox {
  appearance: none;
  background-color: white;
  background-image: url('data:image/svg+xml,\
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10 10">\
      <polyline fill="none" stroke="white" stroke-width="2" points="1 5 4 8 9 2"/>\
    </svg>\
  ');
  border: 1px solid var(--ux-gray-500);
  border-radius: 1px;
  display: inline-block;
  height: 1em;
  margin-right: 0.5rem;
  transition:
    background-color $duration-brief ease,
    border-color $duration-brief ease;
  vertical-align: -0.15em;
  width: 1em;

  &:checked,
  &.checked {
    background-color: $ux-blue-checkbox;
    border-color: $ux-blue-checkbox;
  }

  &:disabled,
  &.disabled {
    opacity: 0.6;
  }

  &:indeterminate,
  &.indeterminate {
    // Using SASS rgba() here is a hack to avoid needing a percent encoding
    // function to deal with the uri-reserved character ‘#’ that appears in hex
    // format colors. AFAIK no such function is built-in w/ sass.

    background-image: url('data:image/svg+xml,\
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10 10">\
        <line stroke="#{rgba($ux-blue-checkbox, 0.999)}" stroke-width="2" x1="2" y1="5" x2="8" y2="5"/>\
      </svg>\
    ');
  }
}

.currency-input {
  position: relative;

  & input {
    padding-left: 1rem;
  }

  &:before {
    position: absolute;
    content: '$';
    padding: 0.45rem;
  }
}

.currency-input.large-input:before {
  padding: 0.8rem 0.45rem;
}

.invalid-feedback.force-display {
  display: block;
}

.pre-wrap {
  white-space: pre-wrap;
}
