$body-fonts: Inter, sans-serif;
$header-fonts: Inter, sans-serif;

// colors to use -- note, look to update `colors.js` when changing these
$ux-white: #fff;
$ux-black: #000;

$ux-blue: #3f6dca;
$ux-navy: #011936;
$ux-cream: #f8f4f2;
$ux-emerald: #158d71;
$ux-gray: #a1a1a1;
$ux-green: #04713c;
$ux-yellow: #f3ce14;
$ux-red: #e03131;
$ux-orange: #f59c27;

$brand-color-facebook: #4c67a1;
$brand-color-google: #4285f4;
$brand-color-linkedin: #0077b5;
$brand-color-office365: #2372ba;
$brand-color-twitter: #1b95e0;
$brand-color-yahoo: #4d00ae;

$ux-gray-light: #f5f5f5; // TODO: Review uses of this and move to gray-100 or gray-200

$ux-blue-checkbox: #164ecf; // TODO: This needs to change in the Design System

// Colors should match palette.scss in 'ui-design-system'
$ux-blue-100: #f1f5fe;
$ux-blue-200: #bdd1fb;
$ux-blue-300: #a6c0f5;
$ux-blue-400: #7497df;
$ux-blue-500: $ux-blue;
$ux-blue-600: #2854af;
$ux-blue-700: #143c8d;
$ux-blue-800: #0b2d72;
$ux-blue-900: #031d52;

$ux-emerald-100: #bfddd5;
$ux-emerald-200: #91cabb;
$ux-emerald-300: #65b8a2;
$ux-emerald-400: #3ea388;
$ux-emerald-500: $ux-emerald;
$ux-emerald-600: #177863;
$ux-emerald-700: #156152;
$ux-emerald-800: #0d4a3e;
$ux-emerald-900: #073b31;

$ux-gray-100: #f9f9f9;
$ux-gray-200: #f1f1f1;
$ux-gray-300: #e1e1e1;
$ux-gray-400: #d1d1d1;
$ux-gray-500: $ux-gray;
$ux-gray-600: #818181;
$ux-gray-700: #616161;
$ux-gray-800: #444;
$ux-gray-900: #101010;

$ux-green-100: #d4f6e5;
$ux-green-200: #a3f9cf;
$ux-green-300: #70e2ab;
$ux-green-400: #40cc88;
$ux-green-500: #21b36c;
$ux-green-600: #09914f;
$ux-green-700: $ux-green;
$ux-green-800: #015029;
$ux-green-900: #003119;

$ux-navy-100: #c4d0df;
$ux-navy-200: #a3bad2;
$ux-navy-300: #7396ba;
$ux-navy-400: #40669c;
$ux-navy-500: #234574;
$ux-navy-600: #16325a;
$ux-navy-700: $ux-navy;
$ux-navy-800: #01152d;
$ux-navy-900: #021020;

$ux-orange-100: #fdead2;
$ux-orange-200: #fcdfb9;
$ux-orange-300: #fac988;
$ux-orange-400: #f7b258;
$ux-orange-500: $ux-orange;
$ux-orange-600: #e18c28;
$ux-orange-700: #ae6608;
$ux-orange-800: #7d4a06;
$ux-orange-900: #653b05;

$ux-red-100: #fae1e1;
$ux-red-200: #f3bdbd;
$ux-red-300: #eb7a7a;
$ux-red-400: #e44e4e;
$ux-red-500: $ux-red;
$ux-red-600: #c71f1f;
$ux-red-700: #b11b1b;
$ux-red-800: #9b1818;
$ux-red-900: #470404;

$ux-yellow-100: #fff1ab;
$ux-yellow-200: #ffeb85;
$ux-yellow-300: #ffe356;
$ux-yellow-400: #fbda36;
$ux-yellow-500: $ux-yellow;
$ux-yellow-600: #e1be0c;
$ux-yellow-700: #caa900;
$ux-yellow-800: #a98d01;
$ux-yellow-900: #453a00;

// used as the primary color, expect it to change
$ux-primary: $ux-emerald;
$ux-researcher: $ux-emerald;
$ux-researcher-light: #bfddd5;
$ux-participant: $ux-emerald;
$ux-participant-dark: darken($ux-participant, 15%);
$ux-save: $ux-green;
$ux-edit: $ux-gray-600;
$ux-cancel: $ux-red;

$ux-divider: #e1e1e1;
$ux-success: $ux-green;
$ux-error: $ux-red;
$ux-warning: $ux-yellow;

// Flash colors
$ux-flash-error: $ux-red-100;
$ux-flash-error-border: $ux-red-200;

$ux-navbar-shadow-color: rgba(0, 0, 0, 26%);
$ux-box-shadow: 0 2px 5px $ux-navbar-shadow-color;
$ux-box-shadow-light: 0 1px 1px $ux-navbar-shadow-color;

$ux-box-shadow-card: 0 2px 4px rgba(0, 0, 0, 10%);

$ux-box-shadow-top: 0 -2px 5px $ux-navbar-shadow-color;
$ux-box-shadow-top-light: 0 -1px 1px $ux-navbar-shadow-color;

// Common dimensions

$ux-border-radius: 0.25rem;

// Navbar
$navbar-default-bg: $ux-white;

// Navbar dimensions
$navbar-height: 48px;

// Navbar links
$nav-link-font-weight: 500;
$nav-link-text-transform: none;
$nav-dropdown-font-weight: 300;
$nav-dropdown-text-transform: none;

$grid-float-breakpoint: 960px;
$header-height: $navbar-height;
$header-height-xs: 48px;

// This zindex is between the header (zindex-fixed) and the modal backdrop (zindex-modal-backdrop)
$z-index-flash: 1045;
