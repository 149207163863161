@mixin ui-badge {
  border-style: solid;
  border-radius: 0.25rem;
  border-width: 0.0625rem;
  display: inline-block;
  font-size: 0.5625rem;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 0.0625rem;
  padding: 0.125rem 0.25rem;
}

@mixin ui-badge-color($theme) {
  background: map-get($theme, background);
  border-color: map-get($theme, border-color);
  color: map-get($theme, color);
}
