@import '../ui_imports';

.form-well {
  .form-section {
    @extend .content-well;

    &__header {
      @extend .content-well__header;

      &__subheader {
        font-size: 1.25rem;
      }
    }

    &__buttons {
      @extend .content-well__buttons;
    }

    &__controls {
      display: flex;
      justify-content: space-between;

      margin-top: 0.5rem;
      margin-bottom: 1rem;

      text-align: center;

      &__menu-icon {
        font-size: 1.5rem;
        cursor: pointer;

        &.placeholder {
          color: rgba(0, 0, 0, 0%);
          cursor: inherit;
        }
      }

      .dropdown-menu {
        cursor: pointer;
      }
    }

    &__grouped {
      @extend .content-well__detail;
      display: flex;
      flex-direction: column;
      padding: 1rem;

      .form-section-content {
        flex-grow: 1;
        margin-top: 1rem;
        max-width: 100%;
        padding-left: 0;

        .form-group {
          display: block;
          margin: 0;
          padding: 0;

          .form-label {
            @include font-type-30;

            font-weight: $headings-font-weight;
            margin-bottom: 0.5rem;
            max-width: 100%;
            padding: 0;
          }

          .form-input {
            max-width: 100%;
            padding: 0;
          }

          + .form-group {
            border-top: 1px solid var(--ux-gray-300);
            margin-top: 1rem;
            padding-top: 1rem;
          }
        }
      }

      .form-section-label {
        @extend .content-well__detail__detail-label;

        flex-basis: 33%;
      }

      @include media-breakpoint-up(sm) {
        flex-direction: row;

        .form-section-content {
          margin-top: 0;
          max-width: 66.7%;
          padding-left: 1rem;
        }
      }
    }
  }

  .form-group {
    @extend .content-well__detail;
  }

  .form-group-spacer {
    height: 1rem;
  }

  .form-input {
    label {
      font-weight: $headings-font-weight;
    }

    &__actions {
      .btn-link {
        padding-top: 0;
      }

      &--desktop {
        @include media-breakpoint-down(md) {
          display: none;
        }
      }

      &--mobile {
        margin-bottom: 0.25rem;

        @include media-breakpoint-up(md) {
          display: none;
        }

        .btn {
          margin: 0.25rem 0;
          width: 100%;
        }
      }
    }

    &__description {
      padding-top: 0.25rem;
      padding-bottom: 0.25rem;

      white-space: pre-wrap;
    }

    &__legend {
      font-family: $font-family-base;
      font-size: $font-size-base;
      font-weight: 300;

      margin: 1rem 0;

      &__field {
        margin-bottom: 0.25rem;

        &__value {
          @include ui-truncate;
          white-space: nowrap;
        }
      }
    }

    &__radio-group {
      label {
        display: inline-block;
        margin-right: 1rem;
      }
    }

    &__select-group {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 0.5rem;
    }
  }

  .form-label {
    @extend .content-well__detail__detail-label;
  }

  .form-submit {
    margin: 1rem 0;
    text-align: center;
  }

  &.horizontal,
  &.vertical {
    .form-group {
      @include make-row;

      margin: 1rem 0;
    }

    .form-group-spacer {
      @include make-col-ready;
      @include make-col(12);
    }
  }

  &.horizontal {
    .form-label {
      @include make-col-ready;
      @include make-col(12);

      margin-bottom: 1rem;

      @include media-breakpoint-up(sm) {
        @include make-col(4);

        margin-bottom: 0;
      }
    }

    .form-input {
      @include make-col-ready;
      @include make-col(12);

      @include media-breakpoint-up(sm) {
        @include make-col(8);
      }
    }
  }

  &.vertical {
    .form-label {
      @include make-col-ready;
      @include make-col(12);

      margin-bottom: 1rem;
    }

    .form-input {
      @include make-col-ready;
      @include make-col(12);
    }
  }
}
