.nav-tabs {
  .nav-link {
    border-top: 0;
    border-left: 0;
    border-right: 0;
  }

  .nav-link.active,
  .active.ui-navbar__link,
  .nav-item.show .nav-link,
  .nav-item.show .ui-navbar__link,
  .show.ui-navbar__item .nav-link,
  .show.ui-navbar__item .ui-navbar__link {
    color: var(--ux-blue);
    border-color: var(--ux-blue);
  }
}

.DrawerBackgroundOverlay {
  z-index: 1000;
}

.Synthesized {
  .btn:not(.Button) {
    &.btn-outline-primary {
      @include synthesis-btn-outline-primary;
    }

    &.btn-primary,
    &.btn-submit {
      color: var(--ux-white);
      background-color: var(--synth-primary-cta-blue);
      border-color: var(--synth-primary-cta-blue);
      &:active {
        background-color: var(--synth-dark-background-pressed-blue);
        border-color: var(--synth-dark-background-pressed-blue);
      }
    }
  }

  .ui-card {
    // TODO: update to use DS Card then remove when DS Card updated for Synthesis
    border: 1px solid #d1d1d1;
    box-shadow: none;
    @include media-breakpoint-up(sm) {
      max-width: 56rem;

      &.card--sm {
        max-width: 40rem;
      }
    }
  }
}

// TODO UIDS:207 Remove these styles once pagination is added to the DS
body:not(.Synthesized) .participants-navigation.pagination {
  margin: 0;
  .page-item {
    .page-link {
      @include font-type-20;
      padding: 0.375rem 0.5rem;
      margin: 0 0.125rem;
      border-radius: 0.125rem;
    }
    &:not(.disabled) .page-link {
      color: var(--ux-blue);
    }

    &.active .page-link {
      @include font-type-20--bold;
      background-color: var(--ux-blue-100);
      border-color: var(--ux-blue);
      color: var(--ux-blue);
    }
  }
}

// TODO UIDS:207 Remove these styles once pagination is added to the DS
.Synthesized .participants-navigation.pagination {
  margin: 0;
  .page-item {
    &:not(.active) .page-link {
      @include synthesis-btn-outline-primary;
    }

    &.active .page-link {
      @include synthesis-btn-primary;
    }

    &.disabled .page-link {
      opacity: 0.65;
    }

    .page-link {
      display: inline-block;
      font-weight: 400;
      line-height: 1.5;
      text-align: center;
      text-decoration: none;
      vertical-align: middle;
      cursor: pointer;
      user-select: none;
      background-color: transparent;
      border: 1px solid transparent;
      border-radius: var(--ux-border-radius);
      transition:
        color 0.15s ease-in-out,
        background-color 0.15s ease-in-out,
        border-color 0.15s ease-in-out,
        box-shadow 0.15s ease-in-out;
      margin: 0 0.125rem;

      &:hover {
        text-decoration: none;
      }
    }
  }
}
