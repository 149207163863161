@import '~common/ui_imports';

.ui-step-list {
  @extend .list-unstyled;

  position: relative;

  li {
    counter-increment: step-counter;
    margin-bottom: 2rem;
    padding-left: 3rem;

    &:last-of-type {
      margin-bottom: 1rem;
    }
  }

  li::before {
    content: counter(step-counter);
    background-color: $ux-researcher;
    color: var(--ux-white);
    font-size: 1rem;
    font-weight: $font-weight-bold;
    margin-left: -3rem;
    padding: 0.25rem 0.75rem;
    position: absolute;
    border-radius: 1rem;
  }
}

.ui-bulleted-list-circle {
  @include ui-remove-list-styles;
  margin: 0.5rem 0;
  padding: 0 0.5rem;

  li {
    margin: 0.5rem;

    &:before {
      color: var(--ux-gray-400);
      content: '\26AA';
      display: inline-block;
      font-size: 0.75rem;
      width: 1.25rem;
    }
  }
}

.bulleted-list--disc {
  list-style-position: inside;
  list-style-type: disc;
}
