// Before using this you may consider adding .btn-link to your element!
@mixin ui-remove-button-styles() {
  background: none;
  border: 0;
  color: inherit;
  font: inherit;
  line-height: normal;
  overflow: visible;
  padding: 0;

  &::-moz-focus-inner {
    border: 0;
    padding: 0;
  }

  &:active,
  &:focus,
  &:hover {
    box-shadow: none;
    color: inherit;
    outline: none;
  }
}
