@import '../../ui_imports';
@import '~bootstrap/scss/buttons';

@mixin button-color($color, $disabled: false) {
  color: $color;

  &:active,
  &:hover,
  &:focus {
    color: $color;
  }

  // Override some very sticky Bootstrap colors
  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active {
    color: $color;
  }

  @if ($disabled) {
    &:disabled {
      color: $color;
    }
  }
}

@mixin button-link-color($color, $darken: true) {
  color: $color;

  &:not(:disabled):not(.disabled) {
    &:active,
    &:focus,
    &:hover {
      @if $darken {
        color: darken($color, 10%);
      } @else {
        color: $color;
      }
    }
  }
}

i,
svg,
img {
  &.icon-left {
    margin-right: 0.5rem;
  }

  &.icon-right {
    margin-left: 0.5rem;
  }
}

.btn[disabled] {
  cursor: not-allowed;
}

.btn:not(.btn-link) {
  @include font-type-30--bold;
}

.btn-link {
  background: transparent;
  border: none;
  color: var(--ux-blue);
  text-decoration: none;

  @include font-type-30--bold;

  &:not(:disabled):not(.disabled) {
    &:active,
    &:focus {
      background: transparent;
      border: 0;
      @include btn-focus-outline;
    }

    &:hover {
      text-decoration: underline;
    }

    &:active:focus {
      box-shadow: none;
    }
  }

  &--block {
    display: block;
  }

  &--bold {
    font-weight: $font-weight-bold;
  }

  &--cancel {
    @include button-link-color($ux-gray-800);
  }

  &--gray-600 {
    @include button-link-color($ux-gray-600);
  }

  &--gray-700 {
    @include button-link-color($ux-gray-700);
  }

  &--gray-800 {
    @include button-link-color($ux-gray-800);
  }

  &--green {
    @include button-link-color($ux-green);
  }

  &--icon-cancel {
    i {
      color: $ux-error;
    }

    @include button-link-color(inherit, false);
  }

  &--neutral {
    @include button-link-color($ux-gray-500);
  }

  &--no-padding {
    padding: 0;
    vertical-align: inherit; // Make these display correctly when used inline in text
  }

  &--primary {
    @include button-link-color($ux-primary);
  }

  &:disabled {
    cursor: not-allowed;
    text-decoration: none;
  }
}

.btn-neutral {
  @include button-variant($ux-gray-500, darken($ux-gray-500, 10%));
  @include button-color($ux-white);
}

.btn-outline-neutral {
  @include button-variant(transparent, $ux-gray-400);
  @include button-color($ux-gray-900);

  &:focus {
    color: var(--ux-gray-900);
  }
}

.btn-outline-gray {
  @include button-variant(transparent, $ux-gray-400);
  @include button-color($ux-gray-700);

  display: flex;
  align-items: center;

  &:focus {
    color: var(--ux-gray-900);
  }
}

.btn-alert-blue {
  @include button-variant($ux-blue-200, $ux-blue-200);
}

.btn-primary {
  // btn-primary mixin from DS to be used until Button component cutover completed
  @include ui-remove-anchor-styles;
  @include synthesis-btn-primary;

  // Removing underlines from any <a> that happens to use this class
  // Using ui-remove-anchor-styles changes the expected color from btn-outline-primary mixin
  &:active,
  &:focus,
  &:hover {
    text-decoration: none;
  }
}

.btn-outline-cancel {
  @include button-outline-variant($ux-error);

  &:hover {
    color: var(--ux-white);
  }
}

.btn-outline-primary {
  // btn-outline-primary mixin from DS to be used until Button component cutover completed
  @include btn-outline-primary;

  // Removing underlines from any <a> that happens to use this class
  // Using ui-remove-anchor-styles changes the expected color from btn-outline-primary mixin
  &:active,
  &:focus,
  &:hover {
    text-decoration: none;
  }
}

.btn-participant {
  @include btn-primary;
}

.btn-social {
  &--email {
    @include button-variant($ux-white, $ux-red);
    @include button-color($ux-red);
  }

  &--facebook {
    @include button-variant(
      $brand-color-facebook,
      darken($brand-color-facebook, 5%)
    );
  }

  &--linkedin {
    @include button-variant(
      $brand-color-linkedin,
      darken($brand-color-linkedin, 5%)
    );
  }

  &--twitter {
    @include button-variant(
      $brand-color-twitter,
      darken($brand-color-twitter, 5%)
    );
  }

  &__divided-content {
    border-left: 1px solid var(--ux-white);
    padding-left: 0.5rem;
  }
}

.btn-submit {
  @include btn-primary;
}

.btn-transparent {
  background-color: transparent;
  color: var(--ux-gray-600);

  &:focus,
  &:hover,
  &.focus {
    background-color: rgba($ux-gray-800, 0.1);
  }
}
