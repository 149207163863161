@import '../ui_imports';

/*
  While this does style a component, there is no reason to not allow HTML / ERB pages
  to use this without using the react component, therefore we should keep this in a
  root common file instead of bundling it along with the LoadingOverlay component - BHS
*/

/*
  NOTE: This requires the parent element to set position: relative,
  otherwise this will fill the whole page
*/

.overlay {
  position: absolute;

  top: 0;
  left: 0;

  height: 100%;
  width: 100%;

  z-index: var(--z-index-overlay);
  background: rgba($ux-white, 0.8);

  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  justify-content: center;

  font-size: 3rem;

  // (50vh - 1.5rem) is a 1/2 height of container excluding navbar
  // Need to subtract another 1.5rem because that's half of font-size above
  &--content-center-overflow {
    padding-top: calc(50vh - 3rem);
    justify-content: unset;
  }

  &--text {
    background-color: white;
    z-index: calc(var(--z-index-fixed) + 1);
  }

  &--content-top {
    padding: 1rem;
    justify-content: flex-start;
  }

  &__header {
    @include font-type-30--bold;
  }
}
