@import '../../ui_imports';
@import '~bootstrap/scss/navbar';

$ui-navbar-spacing: 1rem;

.ui-navbar {
  @extend .navbar;
  @extend .navbar-expand-md;
  @extend .navbar-light;
  justify-content: space-between;

  @include ui-fixed(top);

  background: var(--ux-white);
  box-shadow: var(--ux-box-shadow);
  font-weight: $headings-font-weight;
  height: $ui-navbar-height;
  padding: 0 $ui-navbar-spacing;
  z-index: var(--z-index-ui-navbar);

  &__plus-icon {
    fill: var(--ux-white);
  }

  .ui-navbar__new-project {
    &--desktop {
      display: none;
      @include media-breakpoint-up(lg) {
        display: flex;
        align-items: center;
      }
    }

    &--tablet {
      display: none;

      @include media-breakpoint-between(md, lg) {
        display: inline-block;
        font-size: 1rem;
        padding: 0.25rem;
        width: 30px;
        height: 30px;
      }
    }
  }

  @include media-breakpoint-down(md) {
    height: $ui-navbar-height-mobile;
  }

  &--no-shadow {
    box-shadow: none;
  }

  &__brand {
    @extend .navbar-brand;
    align-items: center;
    display: flex;
    height: $ui-navbar-height;

    @include media-breakpoint-down(md) {
      height: $ui-navbar-height-mobile;
    }
  }

  &__collapse {
    @extend .navbar-collapse;
    justify-content: flex-end;

    @include media-breakpoint-down(md) {
      background: var(--ux-white);
      border-top: 1px solid $ux-gray-light;
      box-shadow: var(--ux-box-shadow);
      margin: 0 (-1 * $ui-navbar-spacing);
      max-height: calc(100vh - 3 * #{$ui-navbar-height-mobile});
      overflow: auto;
    }
  }

  &__call-to-action {
    align-items: center;
    display: flex;

    &:hover {
      background: transparent !important;
    }

    @include media-breakpoint-down(md) {
      .btn {
        width: 100%;
      }
    }
  }

  &__dropdown-menu {
    border-radius: 0.5rem;
    border-top: 0;
    box-shadow: var(--ux-box-shadow);
    margin-top: 0;
    max-height: calc(100vh - 3 * #{$ui-navbar-height});
    overflow: auto;
    padding: 0;
    z-index: var(--z-index-ui-navbar);

    .dropdown-item {
      @include font-type-30;
      background-color: var(--ux-cream);
      text-transform: $nav-dropdown-text-transform;

      &:hover {
        background: $ux-researcher-light;
      }

      &.ui-navbar__call-to-action {
        margin-top: 0.5 * $ui-navbar-spacing;

        .btn {
          width: 100%;
        }
      }
    }

    @include media-breakpoint-down(md) {
      border: 0;
      box-shadow: none;
      display: block;
      margin: 0;
      padding: 0;
      position: static;

      .dropdown-divider {
        display: none;
      }
    }
  }

  &__item {
    @extend .nav-item;
    margin-left: $ui-navbar-spacing;
    text-transform: $nav-link-text-transform;

    @include media-breakpoint-down(md) {
      margin-left: $ui-navbar-spacing;

      &.ui-navbar__call-to-action {
        margin-right: $ui-navbar-spacing;
      }
    }

    &.dropdown {
      @include media-breakpoint-down(md) {
        margin-bottom: $ui-navbar-spacing;
        margin-right: $ui-navbar-spacing;
      }
    }
  }

  &__link {
    @extend .nav-link;
    font-weight: $nav-link-font-weight;
    color: var(--ux-gray-800) !important;

    &.mobile-title {
      display: none;
      font-weight: $nav-link-font-weight;
    }

    &.dropdown-toggle {
      &::after {
        vertical-align: 0.1rem;
      }
    }

    &--green {
      // This is SUPER annoying but bootstrap targets these links for color #444 with a highly
      // specific selector .ui-navbar .ui-navbar__nav .ui-navbar__link which takes precedence over
      // local classes like this.
      color: var(--ux-emerald) !important;
    }

    &--mobile {
      display: none;
    }

    @include media-breakpoint-down(md) {
      padding: (0.33 * $ui-navbar-spacing) 0;

      &--mobile {
        display: initial;
      }

      &.dropdown-toggle {
        display: none;
      }
      &.mobile-title {
        display: block;
      }
    }

    .dropdown-item & {
      font-weight: $nav-dropdown-font-weight;
    }
  }

  &__nav {
    @extend .navbar-nav;
    padding: $ui-navbar-spacing 0;

    @include media-breakpoint-down(md) {
      padding-top: 0.5 * $ui-navbar-spacing;
      background-color: var(--ux-cream);
    }
  }

  // specific selector to override .btn:not(.btn-link) on ui_buttons.scss
  &__new-project.btn:not(.btn-link) {
    display: none;
    font-size: 1.5rem;
    font-weight: $font-weight-bold;
    line-height: 1.25rem;
    padding: 0.25rem;
    width: 30px;
    height: 30px;

    @include media-breakpoint-up(md) {
      display: initial;
    }
  }

  &__teammate-role-tag {
    border-left: var(--ux-gray-500) 1px solid;
    display: none;
    color: var(--ux-green-700);
    font-weight: $font-weight-bold;
    font-style: italic;
    padding-left: 0.625rem;

    @include media-breakpoint-up(md) {
      display: initial;
    }
  }

  &__toggler {
    @extend .navbar-toggler;
    padding: 2px 5px;
  }

  &.participant {
    // some simple color overrides for participant pages... forgive me for !important
    // but overriding the bootstrap colors can be a PITA
    .ui-navbar__brand {
      color: $ux-participant;
      &:link,
      &:hover,
      &:active,
      &:visited,
      &:focus {
        color: $ux-participant;
      }
    }

    .ui-navbar__dropdown-menu .dropdown-item:hover {
      background: $ux-researcher-light;
    }
    .ui-navbar__nav .show > .ui-navbar__link {
      color: $ux-participant !important;
    }
  }
}
