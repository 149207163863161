// The decision to have separate divs and CSS classes here comes from the need to respect the original image size
// but also define a max width/height. Solution partially from:
// https://stackoverflow.com/questions/24197730/css-image-max-width-set-to-original-image-size
// width: 100% on logo-container is set to keep svg images from disappearing:
// https://stackoverflow.com/questions/46922999/inline-svg-disappears-with-flexbox

@import '~common/ui_imports';

.logo-container {
  margin-bottom: 2rem;
  max-height: 100%;
  max-width: 100%;
  width: 100%;

  .logo-thumbnail {
    display: block;
    max-height: 5rem;
    max-width: 15rem;

    @include media-breakpoint-up(md) {
      max-height: 10rem;
      max-width: 30rem;
    }
  }

  &--centered {
    .logo-thumbnail {
      margin: 0 auto;
    }
  }
}
