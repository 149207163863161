@import '~bootstrap/scss/containers';
@import '~bootstrap/scss/grid';

.padded-row {
  margin-top: 3rem;
  margin-bottom: 3rem;
}

.col-centered-form {
  @include make-col-ready;
  @include make-col(12);

  @include media-breakpoint-up(md) {
    @include make-col(8);
    @include make-col-offset(2);
  }

  @include media-breakpoint-up(lg) {
    @include make-col(6);
    @include make-col-offset(3);
  }
}

/* Copying over styles from old world so we can use the same partial */
.row-fineprint {
  @include make-row;

  font-size: 0.75rem;
  margin-bottom: 1rem;
  margin-top: 1rem;
  text-align: center;

  display: none;

  @include media-breakpoint-up(sm) {
    display: block;
  }
}

.col-fineprint {
  @include make-col-ready;
  @include make-col(12);

  @include media-breakpoint-up(sm) {
    @include make-col(10);
    @include make-col-offset(1);
  }
}
